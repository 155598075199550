exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-black-dresses-js": () => import("./../../../src/pages/black-dresses.js" /* webpackChunkName: "component---src-pages-black-dresses-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-formal-dresses-js": () => import("./../../../src/pages/formal-dresses.js" /* webpackChunkName: "component---src-pages-formal-dresses-js" */),
  "component---src-pages-green-dresses-js": () => import("./../../../src/pages/green-dresses.js" /* webpackChunkName: "component---src-pages-green-dresses-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-new-dresses-js": () => import("./../../../src/pages/new-dresses.js" /* webpackChunkName: "component---src-pages-new-dresses-js" */),
  "component---src-pages-pink-dresses-js": () => import("./../../../src/pages/pink-dresses.js" /* webpackChunkName: "component---src-pages-pink-dresses-js" */),
  "component---src-pages-red-dresses-js": () => import("./../../../src/pages/red-dresses.js" /* webpackChunkName: "component---src-pages-red-dresses-js" */),
  "component---src-pages-white-dresses-js": () => import("./../../../src/pages/white-dresses.js" /* webpackChunkName: "component---src-pages-white-dresses-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

